/* Sidebar
   ========================================================================== */

.sidebar {
  width: $sidebar;
  min-height: 100%;
  background: $s-color;
  @include position($position: fixed, $left: 0, $top: 0, $bottom: 0);
  @include flex($display: flex, $direction: column, $j-content: space-between);
  transition: width 0.1s;
  z-index: 1000;

  &__logo {
    height: $header-height;
    background: $s-color;
    align-items: center;
    flex-shrink: 0;
    @include px(rem(15), rem(15));
    @include py(rem(5), rem(5));

    img {
      flex-shrink: 0;
      height: 70%;
    }
  }

  &__logo--main {
    display: flex;

    .sidebar--collapsed & {
      display: none;
    }
  }

  &__logo--min {
    display: none;

    .sidebar--collapsed & {
      @include flex($display: flex, $a-items: center, $j-content: center, $shrink: 0);
      padding: rem(5);
      img {
        flex-shrink: 0;
        height: 70%;
      }
    }
  }

  &__footer {
    width: 100%;
    text-align: center;
    border-top: 1px solid darken($color: $s-color, $amount: 5%);
    @include py(rem(15), rem(15));
    @include px(rem(15), rem(15));

    .sidebar--collapsed & {
      @include px(0, 0);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__version {
    @include py(rem(10), rem(10));
    @include font($color: #4d4f54, $size: rem(10));
  }

  .sidebar--collapsed & {
    width: rem(80);
  }
}

.sidebar-user {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__figure {
    @include flex($display: flex, $a-items: center, $j-content: center);
    width: rem(45);
    height: rem(45);
    border-radius: 100px;
    overflow: hidden;
    flex-shrink: 0;

    &--big {
      width: rem(90);
      height: rem(90);

      .sidebar-user__img {
        width: rem(90);
        height: rem(90);
      }
    }
  }

  &__initials {
    @include flex($display: flex, $a-items: center, $j-content: center, $shrink: 0);
    width: rem(35);
    height: rem(35);
    border-radius: 100px;
    overflow: hidden;
    text-transform: uppercase;
    @include font($size: rem(14), $color: #504a4c);
    background: $b-color;
  }

  &__content {
    @include flex($display: flex, $a-items: center, $grow: 1);

    .sidebar--collapsed & {
      display: none;
    }
  }

  &__img {
    width: rem(45);
    height: rem(45);
    object-fit: cover;
    flex-shrink: 0;
  }

  &__name {
    @include font($size: rem(12), $color: $b-color, $ls: 1px);
    @include px(rem(10), rem(10));
    @include text($align: left, $transform: none);
    flex-grow: 1;
  }

  &__arrow {
    color: $b-color;
  }

  &__dropdown {
    padding: rem(20);
    padding-top: 0;
    padding-bottom: 0;
    background-color: #2e3137;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: rem(70);
    right: 0;
  }

  &__link {
    @include font($size: rem(14), $color: $s-color);
    letter-spacing: 1px;
    @include all-trans;
    height: rem(40);
    @include flex($display: flex, $a-items: center);
    width: 100%;
    border-bottom: 1px solid rgba(130, 131, 135, 0.2);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      text-decoration: none;
      color: $s-color;
      opacity: 0.8;
    }
  }
}

.sidebar-login {
  //padding-bottom: rem(100);
  @include px(rem(30), rem(30));
  @include position($position: fixed, $right: 0, $top: 0, $bottom: 0);
  @include flex($display: flex, $a-items: flex-end);
  background: $s-color;
  min-height: 100%;
  width: $sidebar-login / 2;

  @include respond(desktop) {
    width: $sidebar-login;
  }

  &__body {
    @include flex($display: flex, $direction: column, $a-items: center, $j-content: space-between);
    width: 100%;
    height: 65%;

    img {
      flex-shrink: 0;
    }
  }

  &__version {
    @include font($size: rem(15), $color: $m-color);
    text-align: center;
    width: 100%;
  }
  &__logo {
    @include flex($display: flex, $a-items: center, $j-content: center);
    img {
      flex-shrink: 0;
      max-width: rem(170);
    }
  }

  &__title {
    @include font($size: rem(36), $weight: $fw-black, $color: $b-color);
    @include text($align: center, $transform: uppercase);
  }

  &__subtitle {
    @include font($size: rem(11), $color: $b-color);
    margin-bottom: auto;
    text-align: center;
  }

  &__link {
    width: 100%;
    padding-bottom: rem(15);
    height: rem(30);
    @include text($align: center, $decoration: underline, $transform: uppercase);
    @include font($size: rem(11), $color: $b-color, $ls: 1px);

    &:hover {
      @include all-trans;
      color: #cccccc;
      opacity: 0.8;
    }
  }
}

.is-inverse + .sidebar-login {
  right: initial;
  left: 0;
}
