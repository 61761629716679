/* Timeline
   ========================================================================== */

@mixin timeline {
  display: block;
  line-height: 1;
}

.timeline {
  &__date {
    @include font($size: rem(17), $weight: $fw-black, $color: $k-color);
    @include timeline;
  }

  &__month {
    @include font($size: rem(14), $weight: $fw-black, $color: $t-color);
    @include timeline;
  }

  &__year {
    @include font($size: rem(14), $weight: $fw-regular, $color: $t-color);
    @include timeline;
  }

  &__time {
    @include font($size: rem(11), $weight: $fw-regular, $color: $t-color);
    @include timeline;
  }

  &__icon {
    width: rem(21);
    height: rem(21);
    background: $s-color;
    color: $b-color;
    @include flex($display: flex, $a-items: center, $j-content: center);
    border-radius: rem(100);
    flex-shrink: 0;
    padding: 14px;
  }
}
