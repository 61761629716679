/* Login
   ========================================================================== */
.login {
    min-height: calc(100vh - 30px);
    @include flex($display: flex, $wrap: wrap, $a-items: center, $j-content: center);
    color: $s-color;

    &__block {
        width: 100%;
        @include respond(tablet) {
            width: rem(350);
        }
    }
    
}