.calendar {
    //padding-right: 32px;
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-content: flex-start;
    background: $b-color;

    &__block {
        //flex-basis: 25%;
        min-width: 288px;
        min-height: 252px;
        margin-bottom: 25px;
    }

    &__header {
        height: 32px;
        font-size: 12px;
        font-weight: 400;
        color: #3c4043;
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-right: 3px;
    }

    &__title {
        color: #70757a;
        box-flex: 1;
        flex-grow: 1;
        padding-left: 5px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .25px;
        line-height: 20px;
        color: #3c4043;
    }

    &__body {
        width: 256px;
        //padding: 16px;
        padding: 0 14px 16px 19px;
        position: relative;
        background: $b-color;
    }

    &__grid {
        display: table;
        table-layout: fixed;
        width: 100%;
        text-align: center;
    }

    &__row {
        display: table-row;
        height: 28px;
        display: table;
        table-layout: fixed;
        width: 100%;
        text-align: center;
    }

    &__rowgroup {
        display: table-row-group;
    }

    &__columnheader {
        display: table-cell;
        font-size: 12px;
        font-weight: 400;
        vertical-align: middle;
        color: #9d9d9d;
        text-transform: uppercase;
    }

    &__cell {
        display: table-cell;
        font-size: 10px;
        font-weight: 500;
        vertical-align: middle;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    &__day {
        width: 24px;
        height: 24px;
		line-height: 24px;
		display: block;
        margin: auto;
        border-radius: 50%;
        position: relative;
		background-color: white;
		color: #9d9d9d;
        transition: background-color 100ms linear;
        font-size: 12px;
		font-weight: 400;
		&.is-active {
			color: #3c4043;
		}
		&.holiday {
			background-color: red;
			color: #fff;
        }
        &.today {
			background-color: #1a73e8;
			color: #fff;
        }
        &.scholastic {
            background-color: #f3bc00;
            color: #3c4043;
        }
        &.void {
            background-color: #dadada;
            color: #3c4043;
        }
    }
}