/* Reset
   ========================================================================== */

html,
body {
  height: 100%;
}

body {
  background: $grey-c;
  @include font($family: $p-font, $weight: $fw-regular, $color: $p-color);
}

hr {
  border-color: $grey-b;
}

.list-group-item {
  color: $s-color;
  &.active {
    z-index: 2;
    color: $b-color;
    background-color: $m-color;
    border-color: $m-color;
  }
}

.pagination {
  border-radius: 0;
  .page-link {
    color: $p-color;
  }
  .page-item.active .page-link {
    color: $b-color;
    background-color: $p-color;
    border-color: $p-color;
  }
}

.page-item:first-child .page-link {
  border-radius: 0;
}

.page-item:last-child .page-link {
  border-radius: 0;
}

.dropdown-menu--table {
  background: $p-color;
  border: none;
  border-radius: 0;
  box-shadow: 0px 0px 5px 0px rgba(21, 21, 21, 0.5);
  @include py(0, 0);
  .dropdown-item {
    @include font($size: rem(12), $weight: $fw-regular, $color: $b-color);
    text-transform: uppercase;
    border-bottom: 1px solid #0092d6;
    padding: 0.5rem 0.5rem;
    &:hover,
    &:focus,
    &:active {
      background: #0092d6;
    }
  }
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    background: $p-color;
    display: block;
    position: absolute;
    top: -5px;
    right: 4px;
    transform: rotate(45deg);
  }
}
.modal-backdrop {
  z-index: 799 !important;
}

a {
  text-decoration: none;
}
