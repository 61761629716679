/* Icons
   ========================================================================== */

.icn-rounded {
	width: rem(23);
	height: rem(23);
	border-radius: 100px;
	display: inline-block;
	@include flex($display: flex, $a-items: center, $j-content: center);
	border: 1px solid $k-color;
	opacity: 0.5;
	margin-right: rem(5);
	&__img {
		max-height: rem(10);
	}
}