/* Lists
   ========================================================================== */
.list-group {
	@include font($size: rem(13), $weight: $fw-regular);
	&__title {
		color: $t-color;
	}
	&__subtitle {
		color: $p-color;
	}
	
}