/* Footer
   ========================================================================== */

.footer-login {
    width: 100%;
    text-align: center;
    padding-bottom: rem(15);
    height: rem(30);
    @include font($size: rem(11), $color: $f-color, $ls: 1px);

    @media (min-height: 768px) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }



}