/* Buttons
   ========================================================================== */
a {
  cursor: pointer;
}

button {
  border: none;
  background-color: transparent;
}

*:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: rem(40);
  border-radius: 50px;
  color: $b-color;
  cursor: pointer;
  font-size: rem(13);
  @include py(rem(10), rem(10));
  @include px(rem(15), rem(15));

  @include respond(l-desktop) {
    @include px(rem(20), rem(20));
  }

  @include all-trans;

  &.btn-disabled {
    background: $m-color !important;
    border-color: $m-color !important;
  }

  &.btn-transparent {
    opacity: 0.5;
  }

  &.btn-item {
    height: rem(24);
    padding: 0;
    @include px(rem(8), rem(8));
    @include flex($display: inline-flex, $a-items: center, $j-content: center);
    @include font($size: rem(13), $weight: $fw-medium);
    .btn-item__img {
      width: rem(10);
      margin-right: rem(5);
    }
  }

  &-header {
    padding: 0;
    @include px(rem(4), 0);
  }
  &-timeline {
    padding: 0;
    @include py(rem(4), rem(4));
    img {
      width: rem(20);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-circle {
    width: rem(55);
    height: rem(55);
    padding: 0;
  }

  &-default {
    background: $m-color;
    border-color: $m-color;
    color: $p-color;
    &.matt {
      background-color: rgba($p-color, 0.2);
      color: $p-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: darken($p-color, 10%) !important;
      background-color: darken($m-color, 10%) !important;
      border-color: darken($m-color, 10%) !important;
    }
  }

  &-primary {
    background: $p-color;
    border-color: $p-color;
    &.matt {
      background-color: rgba($p-color, 0.2);
      color: $p-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($p-color, 10%) !important;
      border-color: darken($p-color, 10%) !important;
    }
  }

  &-secondary {
    background: $s-color;
    border-color: $s-color;
    &.matt {
      background-color: rgba($s-color, 0.2);
      color: $s-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($s-color, 10%) !important;
      border-color: darken($s-color, 10%) !important;
    }
  }

  &-success {
    background: $success-color;
    border-color: $success-color;
    &.matt {
      background-color: rgba($success-color, 0.2);
      color: $success-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($success-color, 10%) !important;
      border-color: darken($success-color, 10%) !important;
    }
  }

  &-danger {
    background: $danger-color;
    border-color: $danger-color;
    &.matt {
      background-color: rgba($danger-color, 0.2);
      color: $danger-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($danger-color, 10%) !important;
      border-color: darken($danger-color, 10%) !important;
    }
  }

  &-warning {
    background: $warning-color;
    border-color: $warning-color;
    &.matt {
      background-color: rgba($warning-color, 0.2);
      color: $warning-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($warning-color, 10%) !important;
      border-color: darken($warning-color, 10%) !important;
    }
  }

  &-info {
    background: $info-color;
    border-color: $info-color;
    &.matt {
      background-color: rgba($info-color, 0.2);
      color: $info-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($info-color, 10%) !important;
      border-color: darken($info-color, 10%) !important;
    }
  }

  &-contact {
    background: $contact-color;
    border-color: $contact-color;
    color: $b-color;
    &.matt {
      background-color: rgba($contact-color, 0.2);
      color: $contact-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      border-color: darken($contact-color, 10%) !important;
      background-color: darken($contact-color, 10%) !important;
    }
  }
  &-lead {
    background: $lead-color;
    border-color: $lead-color;
    color: $k-color;
    &.matt {
      background-color: rgba($lead-color, 0.2);
      color: $lead-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      border-color: darken($lead-color, 10%) !important;
      background-color: darken($lead-color, 10%) !important;
    }
  }
  &-account {
    background: $account-color;
    border-color: $account-color;
    color: $b-color;
    &.matt {
      background-color: rgba($account-color, 0.2);
      color: $account-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      border-color: darken($account-color, 10%) !important;
      background-color: darken($account-color, 10%) !important;
    }
  }

  &-light {
    background: $b-color;
    border-color: $b-color;
    color: $p-color;
    &.matt {
      background-color: rgba($b-color, 0.2);
      color: $b-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $p-color;
      background-color: darken($b-color, 10%) !important;
      border-color: darken($b-color, 10%) !important;
    }
  }

  &-dark {
    &.matt {
      background-color: rgba($k-color, 0.2);
      color: $k-color;
      &:hover {
        color: $b-color;
      }
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      background-color: darken($k-color, 10%) !important;
    }
  }

  &-link {
    color: $link-color;
    text-decoration: underline;
    &.matt {
      background-color: rgba($link-color, 0.2);
      color: $link-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: darken($link-color, 10%) !important;
    }
  }

  &-link-primary {
    color: $p-color;
    text-decoration: underline;
    &.matt {
      background-color: rgba($p-color, 0.2);
      color: $p-color;
    }
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: darken($p-color, 10%) !important;
    }
  }

  // Outline Button
  &-outline-default {
    border-color: $m-color;
    color: $m-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($m-color, 10%) !important;
      background-color: darken($m-color, 10%) !important;
    }
  }

  &-outline-primary {
    border-color: $p-color;
    color: $p-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($p-color, 10%) !important;
      background-color: darken($p-color, 10%) !important;
      color: $b-color;
    }
  }

  &-outline-secondary {
    border-color: $s-color;
    color: $s-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($s-color, 10%) !important;
      background-color: darken($s-color, 10%) !important;
    }
  }

  &-outline-success {
    border-color: $success-color;
    color: $success-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($success-color, 10%) !important;
      background-color: darken($success-color, 10%) !important;
    }
  }

  &-outline-danger {
    border-color: $danger-color;
    color: $danger-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($danger-color, 10%) !important;
      background-color: darken($danger-color, 10%) !important;
    }
  }

  &-outline-warning {
    border-color: $warning-color;
    color: $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      border-color: darken($warning-color, 10%) !important;
      background-color: darken($warning-color, 10%) !important;
    }
  }

  &-outline-info {
    border-color: $info-color;
    color: $info-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($info-color, 10%) !important;
      background-color: darken($info-color, 10%) !important;
    }
  }

  &-outline-contact {
    border-color: $contact-color;
    color: $contact-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($contact-color, 10%) !important;
      background-color: darken($contact-color, 10%) !important;
    }
  }
  &-outline-lead {
    border-color: $lead-color;
    color: $lead-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($lead-color, 10%) !important;
      background-color: darken($lead-color, 10%) !important;
    }
  }
  &-outline-account {
    border-color: $account-color;
    color: $account-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($account-color, 10%) !important;
      background-color: darken($account-color, 10%) !important;
    }
  }
  &-outline-light {
    border-color: $b-color;
    color: $b-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: darken($b-color, 10%) !important;
      background-color: darken($b-color, 10%) !important;
      color: $p-color;
    }

    &.is-active {
      border-color: $m-color;
      background: $m-color;
      color: $p-color;
    }
  }

  &-outline-dark {
    border-color: $k-color;
    color: $k-color;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: $k-color;
      background-color: $k-color;
      color: $b-color;
    }

    &.is-active {
      border-color: $k-color;
      background: $k-color;
      color: $b-color;
    }
  }

  &-outline-filter {
    border-color: $p-color;
    color: $p-color;

    &:hover,
    &.is-active {
      border-color: $m-color !important;
      background-color: $m-color !important;
      color: $p-color;
    }

    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      border-color: $p-color;
      color: $p-color;
    }
  }

  &-wizard {
    background: $b-color;
    border-color: $b-color;
    color: $p-color;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $p-color;
      background-color: darken($b-color, 10%) !important;
      border-color: darken($b-color, 10%) !important;
    }
  }

  &-file {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='file'] {
      display: none;
    }
  }

  &-geo {
    background: $s-color;
    border-color: $s-color;
    color: rgba($color: $b-color, $alpha: 0.2);
    border-radius: 0.25rem !important;
    height: rem(52);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:active:focus {
      color: $b-color;
      background-color: darken($s-color, 5%) !important;
      border-color: darken($b-color, 5%) !important;
      .btn-geo__pin {
        opacity: 1;
      }
    }
    &__pin {
      opacity: 0.2;
      margin-right: rem(10);
    }
  }

  &-pay {
    background: $b-color;
    border-radius: 0.25rem !important;
    height: rem(67);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.is-active {
      box-shadow: 0 1px 16px 0 #0087c6;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-collapse {
    @include px(0, 0);

    i {
      display: inline-block;
      @include all-trans;
      color: $s-color;
    }

    &:hover {
      color: $s-color;
      opacity: 0.8;
    }

    .sidebar--collapsed & {
      i {
        transform: rotate(180deg);
      }
    }
  }
  &-footer {
    height: rem(52);
    text-align: center;
    justify-content: center;
    &.is-disabled {
      color: rgba($color: $b-color, $alpha: 0.2);
    }
  }
  &-nogutters {
    padding: 0;
    border: none;
    height: auto;
  }
  &-location__icon {
    display: inline-block;
    @include font($size: rem(24), $color: $p-color);
    margin-top: -5px;
  }

  &-location__icon {
    display: inline-block;
    @include font($size: rem(24), $color: $p-color);
    margin-top: -5px;
  }
}

.btn-lg,
.btn-group-lg > .btn {
  height: rem(55);
  font-size: rem(13);
}

.btn-xl,
.btn-group-xl > .btn {
  height: rem(65);
  font-size: rem(14);
}

.btn-xxl,
.btn-group-xxl > .btn {
  height: rem(80);
  font-size: rem(15);
}

.btn-sm,
.btn-group-sm > .btn {
  height: rem(34);
  font-size: rem(12);
  @include py(rem(7), rem(7));
}

.btn-xs,
.btn-group-xs > .btn {
  height: rem(25);
  font-size: rem(12);
  @include py(0, 0);
}

.btn-square {
  width: rem(50);
  height: rem(50);
  border-radius: 0.25rem !important;
  padding: 0;

  &.btn-sm {
    width: rem(31);
    height: rem(31);
  }
}
