/* Banner bonus
   ========================================================================== */

.banner-bonus {
    @include font($size: rem(12), $color: $b-color, $weight: bold);
    text-transform: uppercase;
    letter-spacing: 1px;
    background: $m-color;
    margin-top: $header-height;
    @include px(rem(10), rem(10));
    @include py(rem(6), rem(6));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
        flex: 1;

        @include respond(tablet) {
            display: flex;
        }

        justify-content: center;

    }

    &__code {
        color: $p-color;
    }

    &__close {
        cursor: pointer;
        color: $s-color;
        @include all-trans;

        &:hover {
            opacity: 0.8;
        }
    }
}

.banner-alert {
    background: #d19eae;
    color: #ff004e;
    padding: rem(30);
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background-color: #ff004e;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}