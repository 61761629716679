/* Modal
   ========================================================================== */

.modal {
    background-color: rgba(0, 0, 0, 0.50);

    &-content {
        background: $b-color;
        position: relative;
        border-radius: 0;
        border: none;
    }

    .modal-header {
        background: $grey-a;
        text-transform: uppercase;

        .modal-title {
            @include font($size: rem(14), $weight: bold, $color: $s-color);
        }
    }

    .modal-footer {
        background: $s-color;
        border-radius: 0;
    }

    .table-light {
        th {
            border-top: 0;
        }

        td,
        th,
        .td,
        .th {
            border-width: 2px;
        }
    }

}

.modal-xxl {
    @include respond(xl-desktop) {
        max-width: 1300px;
    }
}

.modal-full {
    max-width: 95%;
}