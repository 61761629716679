/* Functions
   ========================================================================== */

@function getMargin($width, $margin) {
  @return $width - ($margin * 2);
}

@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  @return ($pxval / $base-font-size) * 1rem;
}

@function img-url($url) {
  @if function-exists(base-url) {
    @return base-url($url);
  } @else {
    @return url($image-path + $url);
  }
}

@function font-url($url) {
  @if function-exists(base-url) {
    @return base-url($url);
  } @else {
    @return url($font-path + $url);
  }
}
