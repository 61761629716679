/* Typography
   ========================================================================== */

@for $size from 10 through 30 {
    .tx-#{$size} {
        font-size: rem($size) !important;
    }
}

.page-header {
    @include py(rem(20),rem(20));
    border-bottom: 1px solid $m-color;
    * {
        margin-bottom: 0;
    }
    &__nav {
        list-style-type: none;
        display: flex;
        padding: 0;
        margin: 0;
    }
    &__nav-item {
        &.is-active {
            .page-header__nav-link {
                color: $s-color;
            }
        }
    }
    &__nav-link {
        padding-left: rem(10);
        padding-right: rem(10);
        font-size: rem(14);
        color: $t-color;
    }

}

.tx-title-1 {
    @include font($size: rem(26), $weight: $fw-bold, $color: $p-color);

    @include respond(l-desktop) {
        font-size: rem(30);
    }
}

.tx-title-2 {
    @include font($size: rem(15), $weight: $fw-bold, $color: $p-color);

    @include respond(l-desktop) {
        font-size: rem(19);
    }
}

.tx-title-3 {
    @include font($size: rem(14), $weight: $fw-bold, $color: $p-color);

    @include respond(l-desktop) {
        font-size: rem(17);
    }
}

.tx-title-4 {
    @include font($size: rem(13), $weight: $fw-bold, $color: $p-color);

    @include respond(l-desktop) {
        font-size: rem(14);
    }
}

.tx-subtitle {
    @include font($size: rem(13), $weight: $fw-light, $color: $t-color);

    @include respond(l-desktop) {
        font-size: rem(14);
    }
}



// Font weight

.tx-thin {
    font-weight: $fw-thin;
}

.tx-x-light {
    font-weight: $fw-x-light;
}

.tx-light {
    font-weight: $fw-light;
}

.tx-regular {
    font-weight: $fw-regular;
}

.tx-medium {
    font-weight: $fw-medium;
}

.tx-s-bold {
    font-weight: $fw-s-bold;
}

.tx-bold {
    font-weight: $fw-bold;
}

.tx-x-bold {
    font-weight: $fw-x-bold;
}

.tx-black {
    font-weight: $fw-black;
}