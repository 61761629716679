/* Header
   ========================================================================== */

.header {
  background: $b-color;
  /* margin-left: $sidebar; */
  @include position($position: relative, $top: 0, $right: 0, $left: 0);
  z-index: 800;
  transition: margin-left 0.1s;
  overflow-x: auto;

  @include respond(l-desktop) {
    @include scrollbar;
  }

  .sidebar--collapsed & {
    /* margin-left: rem(80); */
    overflow-y: inherit;
  }

  &__container {
    /* height: $header-height; */
    @include flex($display: flex, $direction: column, $a-items: center);
    /* margin-right: -14.375rem; */
    overflow-y: hidden;

    .sidebar--collapsed & {
      margin-right: 0;
      overflow-y: visible;
    }
  }
  &__toolbar {
    @include respond(desktop) {
      display: none;
    }
    width: rem(50);
    background: $s-color;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 1000;
    transform: translate(0, -50%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  &__toolbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      @include flex($display: flex, $direction: column, $a-items: center, $j-content: center);
      padding: rem(10);
      &:first-child {
        border-bottom: 1px solid $b-color;
      }
      .icn-rounded {
        width: rem(28);
        height: rem(28);
        margin-right: 0;
        border-color: $b-color;
        opacity: 0.7;
        &__img {
          max-height: rem(12);
        }
      }
    }
  }
}

.header-score {
  @include flex($display: flex, $a-items: center, $j-content: center);
  &__number {
    font-size: rem(30);
    font-weight: $fw-black;
    color: rgba($color: $t-color, $alpha: 0.2);
    &.is-active {
      color: $t-color;
    }
  }
  &__sep {
    font-size: rem(14);
    font-weight: $fw-black;
    color: rgba($color: $t-color, $alpha: 0.2);
    @include px(rem(10), rem(10));
  }
}

.header-sep {
  width: 1px;
  height: rem(40);
  background: $s-color;
}

.header-alert {
  display: flex;
  align-items: center;
  height: rem(40);
  position: relative;
  @include font($size: rem(20), $color: $s-color);
  @include all-trans;

  &:hover {
    color: $s-color;
    opacity: 0.8;
    text-decoration: none;
  }

  &__dot {
    width: rem(8);
    height: rem(8);
    background: #ff004e;
    position: absolute;
    border-radius: 100px;
    top: rem(5);
    right: rem(10);
  }
}

.header-user {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(45);
    height: rem(45);
    border-radius: 100px;
    overflow: hidden;
    flex-shrink: 0;

    &--big {
      width: rem(90);
      height: rem(90);

      .header-user__img {
        width: rem(90);
        height: rem(90);
      }
    }
  }

  &__img {
    width: rem(45);
    height: rem(45);
    object-fit: cover;
    flex-shrink: 0;
  }

  &__name {
    font-size: rem(14);
    color: $s-color;
    @include px(rem(10), rem(10));
    letter-spacing: 1px;
  }

  &__arrow {
    color: $s-color;
  }

  &__dropdown {
    padding: rem(20);
    padding-top: 0;
    padding-bottom: 0;
    background-color: #2e3137;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: rem(70);
    right: 0;
  }

  &__link {
    @include font($size: rem(14), $color: $s-color);
    letter-spacing: 1px;
    @include all-trans;
    height: rem(40);
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(130, 131, 135, 0.2);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      text-decoration: none;
      color: $s-color;
      opacity: 0.8;
    }
  }
}
