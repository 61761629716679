/* Geolocation
   ========================================================================== */


.geo {

   &__map-container {
      position: relative;
   }

   &__map {
      width: 100%;
      height: rem(600);
      border: 0;
   }

   &__location {
      background: $b-color;
      border-radius: rem(12);
      position: absolute;
      left: rem(20);
      top: rem(20);
      right: rem(20);
      overflow: hidden;
      box-shadow: 0px 0px 5px 0px rgba(21, 21, 21, 0.5);
      max-width: rem(450);
   }

   &__location-body {
      padding: rem(20);
   }

   &__location-btn {
      background: $s-color;
      color: $b-color;
      width: 100%;
      height: rem(50);
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font($size: rem(14), $weight: bold, $color: $b-color);


      &:hover {
         color: $b-color;
         background-color: darken($p-color, 10%) !important;
      }
   }

}