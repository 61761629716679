/* Tables
   ========================================================================== */

// Table Dark
.table-dark {
    color: $b-color;
    background-color: transparent;
}

.table-dark {
    .add {
        .tx-label,
        .table-sort i {
            color: $b-color;
        }
    }
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark .th,
.table-dark .td,
.table-dark .thead .th {
    border-color: $p-color;
    border-width: 5px;
    background-color: darken($color:$p-color, $amount: 5%);

    .add & {
        background: darken($color:$p-color, $amount: 10%);
    }

    .settings & {
        background: rgba(0, 0, 0, 0.4);
    }
}

tr.is-selected {
    outline: 2px solid $s-color;
    td {
        background-color: #CCDED2;
        .cl-medium, i {
            color: #aaaaaa!important;
        }
    }
}

// Table Light

.table-light {
    color: #515151;
    background-color: transparent;

    .add {

        .tx-label,
        .table-sort i {
            color: #515151;
        }
    }
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light .th,
.table-light .td,
.table-light .thead .th {
    border-color: #F8F8F8;
    border-width: 7px;
    background-color: $b-color;

    .add & {
        background: #005D8F;
    }

    .settings & {
        background: rgba(0, 0, 0, 0.4);
    }
}


.table-light tr,
.table-light .tr {

    &.is-open {
        background: $p-color;
        color: $b-color;
        margin-bottom: 0;

        th,
        .th,
        td,
        .td {
            background: $p-color;
            border-bottom-color: $b-color;
        }
    }

    &.is-active {
        background: rgba($color: $s-color, $alpha: 0.3);
        color: $b-color;
        margin-bottom: 0;

        th,
        .th,
        td,
        .td {
            background: rgba($color: $s-color, $alpha: 0.3);
        }
    }
}


// Table Midgrey

.table-midgrey {
    color: #515151;
    background-color: transparent;

    .add {

        .tx-label,
        .table-sort i {
            color: #515151;
        }
    }

    .operations td,
    .operations .td {
        border-color: #F8F8F8;
    }

}

.table-midgrey th,
.table-midgrey td,
.table-midgrey thead th,
.table-midgrey .th,
.table-midgrey .td,
.table-midgrey .thead .th {
    border-color: $b-color;
    border-width: 7px;
    background-color: #F8F8F8;

    .add & {
        background: #005D8F;
    }


    .settings & {
        background: rgba(0, 0, 0, 0.4);
    }
}


.table-midgrey tr,
.table-midgrey .tr {

    &.is-open {
        background: $p-color;
        color: $b-color;
        margin-bottom: 0;

        th,
        .th,
        td,
        .td {
            background: $p-color;
            border-bottom-color: $b-color;
        }
    }

    &.is-active {
        background: $s-color;
        color: $b-color;
        margin-bottom: 0;

        th,
        .th,
        td,
        .td {
            background: $s-color;
        }
    }
}




// Table

.table {
    font-size: rem(10);

    i.fas,
    i.far {
        font-size: rem(22);
    }

    i.fa-sort-up,
    i.fa-sort-down {
        font-size: rem(10) !important;
    }

    .tr .td .btn {
        @media (max-width: 1200px) {
            font-size: rem(10);
            width: 100%;
            @include px(rem(5), rem(5));
        }
    }

    @include respond(l-desktop) {
        font-size: rem(15);
        letter-spacing: 1px;

        i.fa-sort-up,
        i.fa-sort-down {
            font-size: rem(10);
        }
    }

    &.is-active-danger {
        background-color: rgba(255, 0, 78, 0.42);
        color: rgba(0, 0, 0, 0.38);

        th,
        .th,
        td,
        .td {
            background-color: rgba(255, 0, 78, 0.42);
            color: rgba(0, 0, 0, 0.38);
        }
    }

    &.is-active-alert {
        background-color: rgba(255, 206, 0, 0.42);
        color: rgba(0, 0, 0, 0.38);

        th,
        .th,
        td,
        .td {
            background-color: rgba(255, 206, 0, 0.42);
            color: rgba(0, 0, 0, 0.38);
        }
    }
}

.table .tr {
    margin-bottom: 7px;
}

.table thead th,
.table thead td,
.table .thead .th,
.table .thead .td {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    white-space: nowrap;

    .add & {
        background-color: transparent;
    }

    .settings & {
        background-color: transparent;
    }
}

.table thead th,
.table .thead .th {
    cursor: pointer;
}

.table th,
.table td,
.table .th,
.table .td {

    vertical-align: middle;

    @include py(rem(20), rem(20));
    @include px(rem(1), rem(1));


    @media (min-width: 769px) {
        @include py(rem(15), rem(15));
    }

    &:first-child {
        padding-left: rem(8);

        @media (min-width: 769px) {
            padding-left: rem(25);
        }
    }

    &:last-child {
        padding-right: rem(8);

        @media (min-width: 769px) {
            padding-right: rem(25);
        }
    }

    .settings & {
        display: flex;
        align-items: center;
    }

}

.table-sort {
    display: inline-flex;
    flex-direction: column;
    height: 8px;

    i {
        color: $s-color;
        font-size: 10px;
        padding-left: 4px;
        line-height: 0;
    }

    .settings & {
        height: auto;
    }
}

.table-icon {
    color: $grey-a;
    position: relative;
    width: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;

    .is-active &,
    .is-open & {
        color: $b-color;
    }

    &__dot {
        background: $danger-color;
        @include font($weight: bold, $size: rem(10), $color: $b-color);
        width: rem(15);
        height: rem(15);
        display: flex;
        border-radius: 100px;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -5px;
        right: -5px;

        .is-active &,
        .is-open & {
            background: $b-color;
            color: $p-color;
        }

        @include respond(desktop) {
            width: rem(18);
            height: rem(18);
            font-size: rem(10);
        }

    }
}

.operations {

    td,
    .td {
        border-color: $b-color;
    }

    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $p-color;
        @include px(rem(20), rem(20));
        @include py(rem(10), rem(10));
    }
}

tr.table-active td,
tr.table-primary td,
tr.table-secondary td,
tr.table-success td,
tr.table-danger td,
tr.table-warning td,
tr.table-info td,
tr.table-light td,
tr.table-dark td {
    background: transparent;
}



// Table Block

.table-block {
    color: #515151;
    background-color: transparent;
    font-size: rem(15);

    .add {

        .tx-label,
        .table-sort i {
            color: #515151;
        }
    }

    .operations td,
    .operations .td {
        border-color: #F8F8F8;
    }

}

.table-block th,
.table-block td,
.table-block thead th,
.table-block .th,
.table-block .td,
.table-block .thead .th {
    border: 7px solid $b-color;
    background-color: #F8F8F8;

    @include py(rem(15), rem(15));
    @include px(rem(15), rem(15));


    @media (min-width: 769px) {
        @include py(rem(15), rem(15));
        @include px(rem(15), rem(15));
    }

    &.table-head {
        padding-left: 0 !important;
        padding-right: 0 !important;
        background: transparent;
        width: rem(60);
        vertical-align: top;
        white-space: nowrap;
        text-align: center;

        @media (min-width: 769px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    &.table-title {
        @include px(0, 0);
        @include py(0, 0);
        background: transparent;
        vertical-align: top;

        @media (min-width: 769px) {
            @include px(0, 0);
            @include py(0, 0);
        }
    }

    &.table-item {
        border: 0;
    }

    &.table-transparent {
        padding: 0 !important;
        background: transparent;

        @media (min-width: 769px) {
            padding: 0 !important;
        }
    }

    &:first-child {
        padding-left: rem(15);

        @media (min-width: 769px) {
            padding-left: rem(15);
        }
    }

    &:last-child {
        padding-right: rem(15);

        @media (min-width: 769px) {
            padding-right: rem(15);
        }
    }

    /*.add & {
        background: #005D8F;
    }*/

    .settings & {
        background: rgba(0, 0, 0, 0.4);
    }


}


.table-block tr,
.table-block .tr {

    &.is-open {
        background: $p-color;
        color: $b-color;
        margin-bottom: 0;

        th,
        .th,
        td,
        .td {
            background: $p-color;
            border-bottom-color: $b-color;
        }
    }

    &.is-active {
        background: $p-color;
        color: $b-color;
        margin-bottom: 0;

        th,
        .th,
        td,
        .td {
            background: $p-color;
        }
    }
}


.table-block td,
.table-block .td {

    &.is-open {
        background: $s-color;
        color: $b-color;
        margin-bottom: 0;
    }

    &.is-active {
        background: $s-color;
        color: $b-color;
        margin-bottom: 0;
    }
}