/* Mixins
   ========================================================================== */

@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media (min-width: 576px) {
      @content
    };
  }

  @if $breakpoint==tablet {
    @media (min-width: 768px) {
      @content
    };
  }

  @if $breakpoint==desktop {
    @media (min-width: 992px) {
      @content
    };
  }

  @if $breakpoint==l-desktop {
    @media (min-width: 1200px) {
      @content
    };
  }

  @if $breakpoint==xl-desktop {
    @media (min-width: 1360px) {
      @content
    };
  }
}

@mixin font($family: null, $size: null, $weight: null, $style: null, $lh: null, $ls: null, $color: null) {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
  font-style: $style;
  line-height: $lh;
  letter-spacing: $ls;
  color: $color;
}

@mixin text($align: null, $decoration: null, $transform: null, $overflow: null) {
  text-align: $align;
  text-decoration: $decoration;
  text-transform: $transform;
  text-overflow: $overflow;
}

@mixin flex($display: null, $direction: null, $wrap: null, $grow: null, $shrink: null, $basis: null, $flow: null, $a-content: null, $a-items: null, $a-self: null,  $j-content: null, $j-items: null, $j-self: null) {
  display: $display;
  flex-direction: $direction;
  flex-wrap: $wrap;
  flex-grow: $grow;
  flex-shrink: $shrink;
  flex-basis: $basis;
  flex-flow: $flow;
  align-content: $a-content;
  align-items: $a-items;
  align-self: $a-self;
  justify-content: $j-content;
  justify-items: $j-items;
  justify-self: $j-self;
}


@mixin position($position: null, $top: null, $bottom: null, $left: null, $right: null) {
  position: $position;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}


@mixin scrollbar {
    /* width */
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 100px;
        transition: background-color 0.5s ease;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #A7A7A7;
        transition: background-color 0.5s ease;
    }
}


@mixin px($padding-left, $padding-right) {
  padding-left: $padding-left;
  padding-right: $padding-right;
}

@mixin py($padding-top, $padding-bottom) {
  padding-top: $padding-top;
  padding-bottom: $padding-bottom;
}

@mixin pl($padding-left) {
  padding-left: $padding-left;
}

@mixin pr($padding-right) {
  padding-right: $padding-right;
}

@mixin pt($padding-top) {
  padding-top: $padding-top;
}

@mixin pb($padding-bottom) {
  padding-top: $padding-bottom;
}

@mixin mx($margin-left, $margin-right) {
  margin-left: $margin-left;
  margin-right: $margin-right;
}

@mixin my($margin-top, $margin-bottom) {
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;
}

@mixin ml($margin-left) {
  margin-left: $margin-left;
}

@mixin mr($margin-right) {
  margin-right: $margin-right;
}

@mixin mt($margin-top) {
  margin-top: $margin-top;
}

@mixin mb($margin-bottom) {
  margin-top: $margin-bottom;
}

@mixin list-none {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin all-trans {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}