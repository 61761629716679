/* Gallery
   ========================================================================== */

$gallery-color: #DDDDDD;

@mixin gallery {
    height: rem(52);
    border-radius: 5px;
    background: $b-color;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16);
}

.gallery {
    display: flex;
    flex-direction: column;

    &__figure {
        position: relative;
        margin-bottom: rem(10);
        height: rem(320);
        overflow: hidden;
        border-radius: rem(10);
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__slide-main {
        display: flex;
        justify-content: space-between;
    }

    &__arrow {
        @include gallery;
        width: rem(52);
        @include flex($display: flex, $a-items: center, $j-content: center, $shrink: 0);
        color: $gallery-color;
        font-size: rem(30);
        text-decoration: none;

        &:hover {
            color: darken($color: $gallery-color, $amount: 10%);
            text-decoration: none;
        }
    }

    &__slide-thumb {
        @include flex($display: flex, $j-content: space-between, $grow: 1);
        @include mx(rem(3), rem(3));
        white-space: nowrap;
        /* [1] */
        overflow-x: auto;
        /* [2] */
        -webkit-overflow-scrolling: touch;
        /* [3] */
        -ms-overflow-style: -ms-autohiding-scrollbar;

        /* [4] */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__fig-thumb {
        @include gallery;
        min-width:  rem(65);
        flex-shrink: 0;
        overflow: hidden;
        margin-bottom: 0;
        @include mx(rem(3), rem(3));
        &.is-box {
            height: rem(71);
            margin-bottom: 0.5rem;
        }
    }

    &__thumb {
        min-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__file {
        @include gallery;
        width: rem(52);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        overflow: hidden;
        @include flex($display: flex, $a-items: center, $j-content: center, $shrink: 0);
        color: $gallery-color;
        font-size: rem(30);
        margin-left: rem(6);

        input[type="file"] {
            display: none;
        }

        &:hover {
            color: darken($color: $gallery-color, $amount: 10%);
            text-decoration: none;
        }
        &.is-box {
            width: auto;
            min-width: rem(71);
            height: rem(71);
            margin-bottom: 0.5rem;
        }
    }
    &__map {
        height: 175px;
        width: 100%;
        border-radius: rem(10);
    }
}