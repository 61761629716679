/* Navbar
   ========================================================================== */

.navbar {
  align-items: flex-start;
  padding-top: 0;
  @include px(0, 0);
  @include all-trans;
  margin-bottom: auto;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  .navbar-nav {
    width: 100%;
  }

  .nav-item {
    position: relative;
  }

  .nav-link {
    position: relative;
    @include flex($display: flex, $a-items: center);
    @include font($size: rem(16), $weight: $fw-regular, $color: rgba($color: $b-color, $alpha: 0.5));
    @include py(rem(15), rem(15));
    @include px(rem(15), rem(15));
    .sidebar--collapsed & {
      justify-content: center;
    }
    &:hover {
      opacity: 0.8;
    }

    &.active {
      background: darken($color: $s-color, $amount: 5%);
      @include font($weight: $fw-bold, $color: $b-color);

      &:after {
        content: '';
        display: block;
        width: 5px;
        height: 100%;
        background: lighten($color: $s-color, $amount: 5%);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }

      .nav-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .nav-label {
    font-size: rem(16);
    padding-left: rem(10);
    flex: 1;

    .sidebar--collapsed & {
      display: none;
    }
  }

  .nav-icon {
    color: $m-color;
  }

  .nav-arrow {
    display: inline-block;
    @include all-trans;
    font-size: rem(7);

    .sidebar--collapsed & {
      margin-left: rem(8);
    }
  }

  .nav-dropdown {
    @include font($size: rem(16), $weight: $fw-bold, $color: $b-color);
    background: darken($color: $s-color, $amount: 5%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      color: $b-color;
    }
  }
}

.nav-collapse {
  background: darken($color: $s-color, $amount: 5%);
}

.subnav {
  @include list-none;

  .nav-link {
    @include font($size: rem(14), $weight: $fw-regular, $color: rgba($color: $b-color, $alpha: 0.5));
    position: relative;
    text-transform: none;

    &.active {
      background: darken($color: $s-color, $amount: 7%);
      @include font($weight: $fw-bold, $color: $b-color);
    }
  }
}

// Page Navbar
.page-navbar {
  padding-bottom: rem(6);
  white-space: nowrap; /* [1] */
  overflow-x: auto; /* [2] */
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
  &__nav {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__item {
    @include px(0, rem(20));
  }
  &__link {
    border-bottom: 2px solid transparent;
    color: $f-color;
    @include all-trans;
    font-size: rem(12);
    padding-bottom: rem(7);
    text-transform: capitalize;
    &:hover {
      color: $s-color;
      border-bottom: 2px solid $s-color;
      text-decoration: none;
      opacity: 0.8;
    }
    &.is-active {
      color: $s-color;
      border-bottom: 2px solid $s-color;
    }
  }
}
